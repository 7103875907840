
import {
  onMounted,
  onUnmounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBodyPager } from "@/apis/interface/Resp";
import {
  IAdminLog,
  AdminLogFilter,
  AdminLogModel
} from "@/apis/interface/IAdminLog";
import bus from "@/utils/bus";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {},
  setup() {
    const apiAdminLog = inject("apiAdminLog") as IAdminLog;

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: AdminLogFilter = reactive({
      adminId: null,
      option: "",
      message: "",
      level: null
    });

    let clearFilter = () => {
      filter.adminId = null;
      filter.option = "";
      filter.message = "";
      filter.level = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<AdminLogModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiAdminLog.getList(pageParams, filter);
      const pager = resp as RespBodyPager<AdminLogModel>;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let deleteRow = async (id: number) => {
      let result = await apiAdminLog.remove([id]);
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let tableHeight = ref(0);

    return {
      loading,
      pageParams,
      filter,
      dataList,
      tableHeight,
      loadData,
      deleteRow,
      clearFilter
    };
  }
});
